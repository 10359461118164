import React from 'react';
import './App.css';
import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';

function Menu() {
    return <div className="Menu">
        <img src="/images/italiaanse-windhond.png"/>
        <NavLink to='/'>Info</NavLink>
        <NavLink to='/verzorging'>Verzorging</NavLink>
        <NavLink to='/adoptie'>Adoptie</NavLink>
        <NavLink to='/fokkers'>Fokkers</NavLink>
    </div>;
}

function Footer() {
    return <div className="Footer">
        <div className="Column"></div>
        <div className="Column"></div>
        <div className="Column">
        </div>

    </div>;
}

function Home() {
    document.title = 'Italiaanse Windhond';
    return <>
        <h1>Info</h1>
        <h2>Geschiedenis</h2>
        <div className="Paragraph">
            <p>Het Italiaanse windhondje is een oud ras bevestigd door de vondst van tweeduizend jaar oude geraamtes. Het kreeg zijn naam tijdens de Italiaanse renaissance, maar is wellicht afkomstig uit Turkije tot Egypte. Het diente als jachthond en misschien als eerste gezelschapshond, bevestigd door aanwezigheid in Pompei en Egyptische graven. In de middeleeuwen verspreidde het zich over Europa en werd populair onder adel en koninklijke families. Het wordt geclassificeerd als windhond met uitstekend zicht en snelheid bij jagen.</p>
            <div><img src="/images/img_1.jpg"/></div>
        </div>
        <h2>Gedrag</h2>
        <div className="Paragraph">
            <img src="/images/img.png"/>
            <p>Het Italiaanse windhondje is een slim, trouw en gevoelig ras dat graag bij je is en houdt van knuffelen. Ze maken het zich graag gemakkelijk in je bed, of je er nu in ligt of niet. Ze kunnen goed overweg met kinderen, maar omdat ze vrij fragiel zijn, zijn ze minder geschikt voor ruw spel. Door hun sterk jachtinstinct rennen ze achter katten en andere kleine dieren waar ze nog geen sociale band mee hebben ontwikkeld. Tegen onbekenden zijn ze vaak afstandelijk en dienen als goede waakhonden. Training met andere mensen/plaatsen en geluiden is belangrijk voor de ontwikkeling van evenwichtige dieren.

                Het Italiaanse windhondje vindt het heerlijk om te spelen met andere honden, maar te ruig spel houden ze niet van. Het ras is slim maar eigenwijs, dus houd trainingssessies kort en wees vriendelijk en liefdevol. Het Italiaanse windhondje heeft veel beweging nodig en is een uitstekende hardloopmaat. Ze zijn geen fan van koud/nat weer en hebben bij koudere temperaturen een hondenjas nodig. Dit ras houdt van activiteiten zoals agility en lure coursing en bewondering en affectie van de eigenaar is de enige beloning die ze nodig hebben.

                Hun korte vacht vereist weinig verzorging. Over het algemeen bereiken ze een redelijke leeftijd, maar ze zijn gevoelig voor staar, progressieve retina atrofie en soms epilepsie.</p>
        </div>
        <h2>Karakter</h2>
        <div className="Paragraph">
            <p>Italiaanse windhondjes zijn gevoelig en toegewijd met een voorkeur voor comfort en knuffels van hun baasjes. Net als andere windhonden zijn ze snel buiten. Goede socialisatie is belangrijk om verlegenheid te voorkomen en ze kunnen aanhankelijk zijn naar hun baasjes, hoewel ze onbekenden het liefst vermijden.</p>
            <div><img src="/images/img_2.jpg"/></div>
        </div>
    </>;
}

function Adoptie() {
    document.title = 'Italiaanse Windhond adoptie';
    return <>
        <h1>Adoptie</h1>
        <div className="Paragraph">
            <p>Vooraleer u overweegt een Italiaanse Windhond te adopteren, is het belangrijk om u bewust te zijn van hun noden en verwachtingen.
                De Italiaanse Windhond heeft graag veel beweging, vooral op jongere leeftijd. Ze vinden het fijn om te kunnen lopen in een tuin, park, speelweide of op strand.
                Als u al een hond of kat heeft, is het raadzaam om de nodige tijd te nemen om ze aan elkaar te laten wennen. Eenmaal aan elkaar gewend zijn ze vaak onafscheidelijk. Het is altijd belangrijk om de verantwoordelijkheid van het adopteren van een huisdier serieus te nemen en ervoor te zorgen dat u voldoende tijd en middelen heeft om voor hen te zorgen.
                In ruil voor deze zorgen krijgt u heel veel liefde terug.
            </p>
            <div><img src="/images/img_4.jpg"/></div>
        </div>

    </>;
}

function Verzorging() {
    document.title = 'Italiaanse Windhond verzorging';
    return <>
        <h1>Verzorging</h1>
        <div className="Paragraph">
            <div><img src="/images/img_5.jpg"/></div>
            <p>Een Italiaanse windhond is niet moeilijk te verzorgen aangezien hij een hele makkelijke vacht heeft. Borstelen is bijna niet nodig. Gewoon met een doek over zijn vacht wrijven is de beste manier om zijn vacht te onderhouden.
                Op deze manier blijft zijn vacht mooi glanzend.<br/>

                Zijn gebit heeft meer aandacht nodig. Om tandsteen te voorkomen kan je best regelmatig zijn tanden poetsen.<br/>

                Een Italiaanse Windhond is fijn van gestalte echter hebben ze veel energie waardoor ze energierijke voeding nodig hebben.<br/>
                Het is belangrijk om de Italiaanse windhond regelmatig uit te laten voor genoeg beweging en activiteit, omdat ze een actieve hondenras zijn. Ze hebben ook regelmatig training nodig om hun geest te prikkelen en te voorkomen dat ze vervelen. Het is belangrijk om hen op een juiste manier te trainen en socialiseren om gedragsproblemen te voorkomen.
            </p>
        </div>
    </>;
}

function Fokkers() {
    document.title = 'Italiaanse Windhond Fokkers';
    return <>
        <h1>Fokkers</h1>
        <h2>Alfons Van Roeyen</h2>
        <p>Alfons Van Roeyen is een hobbymatige fokker van uitsluitend Italiaanse Windhonden met meer dan 50 jaar ervaring.</p>
        <ul>
            <li>Alfons Van Roeyen</li>
            <li>Arkestraat 35</li>
            <li>9111 Belsele</li>
            <li>Belgie</li>
            <li>+32 475 74 58 39</li>
        </ul>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p><br/></p>
        <p><i><small>Bent u fokker van uitsluitend Italiaanse Windhonden en wenst u hier vermeld te worden ? Neem dan contact op met ons om meer informatie te ontvangen over de voorwaarden.</small></i></p>
    </>
}

function Contact() {
    return <h1>INFO</h1>;
}

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <Menu/>
                </header>

                <div className="Page">
                    <Routes>
                        <Route path='/' element={<Home/>}/>

                        <Route path='/verzorging' element={<Verzorging/>}/>
                        <Route path='/adoptie' element={<Adoptie/>}/>
                        <Route path='/fokkers' element={<Fokkers/>}/>
                        <Route path='/contact' element={<Contact/>}/>
                        <Route path='*' element={<p>Default</p>}/>
                    </Routes>
                </div>
                <footer className="App-footer">
                    <Footer/>
                </footer>
            </div>
        </BrowserRouter>
    );
}

export default App;
